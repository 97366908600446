import { useCheckoutStore } from '~/stores/checkout'
import { useCheckoutStoreV3 } from '@/stores/checkout-v3'
import { useCheckoutCredit } from '@/stores/checkout_credit'

export default {
  setPaymentMethod() {
    // 'CASH_ON_DELIVERY' - cash -"Наличными"
    // 'CARD_ON_DELIVERY - card_courier  "Банковской картой при получении"
    // 'CARD_ECOM_KB' - card_online "Банковской картой онлайн"
    // 'LOAN_ON_DELIVERY' -loan - рассрочка онлайн
    // 'UMICO_BONUS' -bonus - юмико бонус
    return method => {
      let payment_method
      switch (method) {
        case 'CASH_ON_DELIVERY':
          payment_method = 'cash'
          break
        case 'CARD_ON_DELIVERY':
          payment_method = 'card_courier'
          break
        case 'CARD_ECOM_KB':
          payment_method = 'card_online'
          break
        case 'LOAN_ON_DELIVERY':
          payment_method = 'credit'
          break
        case 'BB_CARD_ECOM_KB':
          payment_method = 'loan'
          break
        case 'CARD_ECOM_KB_INSTALLMENT':
          payment_method = 'card_online'
          break
        case 'UMICO_BONUS':
          payment_method = 'bonus'
          break
        default:
          payment_method = 'other'
      }
      return payment_method
    }
  },
  setDeliveryMethod() {
    return (setMethod = '') => {
      const CheckoutStore = useCheckoutStore()
      const method = setMethod || CheckoutStore.orderPackage?.shipping?.method
      let delivery_method
      switch (method) {
        case 'PUDO':
          delivery_method = 'self_pickup'
          break
        case 'COURIER_STANDARD':
          delivery_method = 'courier_delivery'
          break
        case 'COURIER_EXPRESS':
          delivery_method = 'express_delivery'
          break
        case 'PUDO_AZERPOCT':
          delivery_method = 'azerpoct'
          break
        default:
          delivery_method = 'other'
      }
      return delivery_method
    }
  },
  creditErrorLoanApplicationForm() {
    return () => {
      const listError = []
      const checkoutStoreV3 = useCheckoutStoreV3()
      const { loanApplication } = checkoutStoreV3

      if (!loanApplication?.additional_phone_number1)
        listError.push('trusted_number_1')
      if (!loanApplication?.additional_phone_number2)
        listError.push('trusted_number_2')
      if (!loanApplication?.first_name) listError.push('name')
      if (!loanApplication?.last_name) listError.push('surname')
      if (!loanApplication?.phone_number) listError.push('phone_number')
      if (!loanApplication?.fin_code) listError.push('fin_code')

      console.log('listError', listError)

      return listError?.length ? listError : []
    }
  },
  paymentMethodForCartPage() {
    return route => {
      const creditCheckout = useCheckoutCredit()
      return route?.query?.mode === 'loan'
        ? creditCheckout.checkFirstCredit
          ? 'credit_new'
          : 'credit_old'
        : 'regular'
    }
  },
}
