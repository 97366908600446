import { mapGetters } from 'vuex'
import { scrollTo } from '@/utilities'
import seoDataLayer from '@/mixins/setDataLayer'
import { useCheckoutStore } from '@/stores/checkout'
import { useCheckoutStoreV3 } from '@/stores/checkout-v3'
import { useUiStore } from '@/stores/ui'
import { useEventDataLayer } from '@/stores/event_data_layer'

export default {
  data() {
    return {
      isOpenAvailablePopup: false,
    }
  },
  mixins: [seoDataLayer],
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    listCartProductsLineItems() {
      return (
        this.marketStore?.cart?.sellers?.flatMap(seller => seller.line_items) ||
        []
      )
    },
    leastOneCheckNeedProgress() {
      let check_need = false
      this.listCartProductsLineItems.forEach(item => {
        if (
          item.availability_check_needed &&
          item.availability_check_state === 'IN_PROGRESS'
        ) {
          check_need = true
        }
      })
      return check_need
    },
    leastOneMoreAvailable() {
      let moreAvailable = false
      this.marketStore.cart.sellers?.forEach(partner => {
        partner.line_items.forEach(item => {
          if (
            item.allowed_quantity !== 0 &&
            item.quantity > item.allowed_quantity &&
            item.min_quantity < item.allowed_quantity
          ) {
            moreAvailable = true
          }
        })
      })
      return moreAvailable
    },
    leastOneAvailable() {
      let available = false
      this.listCartProductsLineItems.forEach(item => {
        if (
          item.availability_check_state === 'AVAILABLE' &&
          item.state === 'active'
        ) {
          available = true
        }
      })
      return available
    },
    everethingNotAvailable() {
      return this.marketStore.cart?.sellers.every(partner =>
        partner.line_items.every(product => product.state !== 'active'),
      )
    },
    checkHasGrosseryProducts() {
      const partners_grossery = this.marketStore.cart?.sellers
        .find(
          partner => partner?.id == this.grosseryStore.grossery_merchant_uid,
        )
        ?.line_items.filter(product => {
          return product.is_selected_for_checkout && product.state === 'active'
        })
      const ather_partners = this.marketStore.cart?.sellers.filter(
        partner => partner?.id !== this.grosseryStore.grossery_merchant_uid,
      )
      const ather_partners_products = []
      ather_partners.forEach(partner => {
        const is_product = partner.line_items.filter(product => {
          return product.is_selected_for_checkout && product.state === 'active'
        })
        if (is_product?.length) {
          ather_partners_products.push(...is_product)
        }
      })
      return (
        !['GROCERY', 'NOT_GROCERY'].includes(
          this.grosseryStore.typeGrosseryCartMode,
        ) &&
        Boolean(partners_grossery?.length && ather_partners_products?.length)
      )
    },
    setGrosseryOrNotGrossery() {
      const partners_grossery = this.marketStore.cart?.sellers
        .find(
          partner => partner?.id == this.grosseryStore.grossery_merchant_uid,
        )
        ?.line_items.filter(product => {
          return product.is_selected_for_checkout && product.state === 'active'
        })
      const ather_partners = this.marketStore.cart?.sellers.filter(
        partner => partner?.id !== this.grosseryStore.grossery_merchant_uid,
      )
      const ather_partners_products = []
      ather_partners.forEach(partner => {
        const is_product = partner.line_items.filter(product => {
          return product.is_selected_for_checkout && product.state === 'active'
        })
        if (is_product?.length) {
          ather_partners_products.push(...is_product)
        }
      })
      return ['GROCERY', 'NOT_GROCERY'].includes(
        this.grosseryStore.typeGrosseryCartMode,
      )
        ? this.grosseryStore.typeGrosseryCartMode
        : partners_grossery?.length
        ? 'GROCERY'
        : ather_partners_products?.length
        ? 'NOT_GROCERY'
        : 'ALL_PRODUCTS'
    },
    calculateCartAmount() {
      if (this.grosseryStore.typeGrosseryCartMode === 'ALL_PRODUCTS') {
        return this.marketStore.cart?.total_amount
      }
      if (this.grosseryStore.typeGrosseryCartMode === 'NOT_GROCERY') {
        let summ = 0
        this.marketStore.cart?.sellers?.map(seller => {
          if (seller.id !== this.grosseryStore.grossery_merchant_uid)
            summ += seller.amount
        })
        return summ
      }
      if (this.grosseryStore.typeGrosseryCartMode === 'GROCERY') {
        let summ = 0
        this.marketStore.cart?.sellers?.map(seller => {
          if (seller.id === this.grosseryStore.grossery_merchant_uid)
            summ += seller.amount
        })
        return summ
      }
    },
    totalAmountNotGrosseryPtoducts() {
      return (
        this.marketStore.cart?.total_amount -
        this.grosseryStore.grossery_total_amount
      )
    },
    checkFirstCredit() {
      return (
        this.credit_user_balance &&
        Object.keys(this.credit_user_balance)?.length === 0 &&
        Object.getPrototypeOf(this.credit_user_balance) === Object.prototype
      )
    },
    creditNotAvailable() {
      return Boolean(
        this.checkoutCredit.credit_user_balance?.avail_limit_max == 0 ||
          new Date(
            this.checkoutCredit.credit_user_balance?.сl_actl_end_date,
          ).getTime() < Date.now(),
      )
    },
  },
  watch: {
    continueCheckoutWithoutGrossery(val) {
      if (val) {
        this.goToCheckout()
        this.continueCheckoutWithoutGrossery = false
      }
    },
  },

  methods: {
    async goToCheckout() {
      const uiStore = useUiStore()
      uiStore.isPopupCartMinAmount = false

      if (!this.isAuthenticated) {
        if (
          this.$route.name?.includes('cart___') ||
          this.$route.name?.includes('index___')
        ) {
          this.$auth.$storage.setUniversal(
            'nextPage',
            `cart___${this.$i18n.locale}`,
          )
        }
        this.$router.push({
          path: this.localePath('auth-authentication'),
        })
        return
      }
      if (this.calculateCartAmount < this.marketStore.minOrderAmount) {
        await this.goToCart()
        // Минимальная сумма заказа 2 ₼
        uiStore.isPopupCartVisible = false
        uiStore.isPopupCartMinAmount = true
        this.grosseryStore.typeGrosseryCartMode = 'ALL_PRODUCTS'
        return
      }

      if (this.checkHasGrosseryProducts) {
        // Выберите какие товары вы хотите заказать сейчас:
        // У вас в корзине товары одновременно с обычной доставкой и возможностью экспресс доставки из Dükan
        this.grosseryStore.showPopupChooseGrosseryCheckout = true
        return
      } else {
        this.grosseryStore.updateTypeGrosseryCartMode(
          this.setGrosseryOrNotGrossery,
        )
      }
      if (!this.leastOneAvailable && !this.everethingNotAvailable) {
        // Вы сможете оформить заказ после того, как наличие товаров будет подтверждено.
        // Мы оповестим вас в приложении и отправим SMS на телефон
        uiStore.availabilityGoodsSpecified = true
        return
      }

      if (this.marketStore.customerForOrder) {
        this.marketStore.customerForOrder = null
      }
      if (this.leastOneMoreAvailable) {
        // Ограниченное предложение
        // Ранее вы добавили товары в корзину, которые сейчас доступны по акционной цене,
        // но в ограниченном количестве.
        // Пожалуйста, уменьшите количество товаров с ограниченным предложением.
        this.isOpenAvailablePopup = true
      } else if (this.leastOneCheckNeedProgress) {
        uiStore.isCheckoutWarningPopup = {
          visible: true,
          buttonFunc: this.goToCheckoutFunc,
        }
      } else if (this.everethingNotAvailable) {
        uiStore.isBasketNotAvailablePopup = true
      } else {
        this.goToCheckoutFunc()
      }
      const checkoutStore = useCheckoutStore()
      checkoutStore.warning_merge_anonymous_cart = false
    },
    async goToCheckoutFunc() {
      this.continueCheckoutWithoutGrossery = false
      // // TODO remove before RELEASE from
      // const type_checkout = localStorage.getItem('type_checkout')
      // if (type_checkout === '3') {
      //   // TODO remove before RELEASE to
      //   const checkoutStoreV3 = useCheckoutStoreV3()
      //   // checkoutStoreV3.postOrdersPackages(
      //   //   this.grosseryStore.typeGrosseryCartMode,
      //   // )
      // }
      const query = {}
      if (this.grosseryStore.isTypeGrossery) {
        query.grocery = 'grocery'
      }
      try {
        this.$router.push(
          this.localePath({
            name: 'checkout',
            query,
          }),
        )
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },

    async goToCreditCheckout(confirm) {
      /* eslint-disable */
      // this.uiStore.creditLineNotAvailableModal = true
      // return

      if (!this.customerStore.isLoggedIn) {
        await this.$router.push(
          this.localePath({
            name: 'checkout-authentication',
            query: {
              mode: 'loan',
            },
          }),
        )
        return
      }
      /* eslint-disable */
      // this.grosseryStore.showPopupChooseGrosseryCheckoutCredit = true // Товары с экспресс доставкой из Dükan на данный момент невозможно оформить в кредит
      // this.grosseryStore.showPopupCheckoutCreditNotAvailable = true // Кредит пока не доступен Минимальная сумма заказа в кредит от 50 ₼.
      // this.uiStore.isPopupCartMinAmount = true // Минимальная сумма заказа 2 маната
      // this.uiStore.creditLineNotAvailableModal = true // Кредитная линия недоступна обращайтесь по номеру телефона 196
      // this.uiStore.inSufficientCreditModal = true // (УДАЛЁН) возвращён - Недостаточно кредитных средств Минимальный лимит для оформления заказа в кредит составляет 100 манат
      // this.showWarnPopupCreditUnavailable = true // Кредит пока недоступен 50 ₼
      // this.uiStore.buyOnCreditPopupWarning  = true // Недостаточно кредитных средств Сумма заказа с учетом комиссии по кредиту превышает доступный
      // this.uiStore.isOpenAvailablePopup = true  //Ограниченное предложение Ранее вы добавили товары в корзину, которые сейчас доступны по
      // this.uiStore.isBasketNotAvailablePopup = true // Товары в корзине недоступны для заказа Выбранных вами товаров нет в наличии или они недоступны для

      if (
        this.customerStore.isLoggedIn &&
        this.marketStore.cart?.credit_allowed === 'NOT_AVAILABLE'
      ) {
        await this.goToCart()
        this.uiStore.buyOnCreditPopupWarning = true // Товары в корзине недоступны для покупки в кредит
        return
      }

      if (this.checkHasGrosseryProducts) {
        if (
          this.totalAmountNotGrosseryPtoducts >= 50 &&
          this.checkFirstCredit
        ) {
          await this.goToCart()
          this.grosseryStore.showPopupChooseGrosseryCheckoutCredit = true // Товары с экспресс доставкой из Dükan на данный момент невозможно оформить в кредит
          return
        }
        if (this.totalAmountNotGrosseryPtoducts < 50 && this.checkFirstCredit) {
          await this.goToCart()
          this.grosseryStore.showPopupCheckoutCreditNotAvailable = true // Кредит пока не доступен Минимальная сумма заказа в кредит от 50 ₼.
          return
        }
        if (this.maxCreditLimit) {
          if (
            this.maxCreditLimit > 0 &&
            this.maxCreditLimit > this.grosseryStore.grossery_total_amount
          ) {
            await this.goToCart()
            this.grosseryStore.showPopupChooseGrosseryCheckoutCredit = true // Товары с экспресс доставкой из Dükan на данный момент невозможно оформить в кредит
            return
          }
        }
        await this.goToCart()
        this.grosseryStore.showPopupChooseGrosseryCheckoutCredit = true // Товары с экспресс доставкой из Dükan на данный момент невозможно оформить в кредит
        return
      }

      const notAvailableFirstCredit =
        this.checkoutCredit.checkFirstCredit &&
        this.customerStore.isLoggedIn &&
        this.marketStore.cart?.credit_allowed === 'NOT_AVAILABLE_BY_AMOUNT'

      if (
        this.marketStore.cart?.total_amount < this.marketStore.minOrderAmount &&
        !this.checkFirstCredit
      ) {
        await this.goToCart()
        this.uiStore.isPopupCartVisible = false
        this.uiStore.isPopupCartMinAmount = true // Минимальная сумма заказа 2 маната
        return // MIN Amount from beck
      }
      if (this.creditNotAvailable) {
        await this.goToCart()
        this.uiStore.creditLineNotAvailableModal = true // Кредитная линия недоступна обращайтесь по номеру телефона 196
        return
      }

      // ВОЗВРАЩЕНО ИЗ ЗА ОШИБКИ НА ВБОРЕ МЕТОДА ОПЛАТЫ так как при кейсе что товар не доступен в кредит метод оплаты не поиходит
      if (
        this.isAuthenticated &&
        !this.checkoutCredit.checkFirstCredit &&
        this.checkoutCredit.minCreditLimit()
      ) {
        await this.goToCart()
        this.uiStore.inSufficientCreditModal = true
        return
      }

      if (this.marketStore.cart?.total_amount < 50 && this.checkFirstCredit) {
        await this.goToCart()
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({
            event: 'credit_scr_min_sum_false',
          })
        }
        this.uiStore.showWarnPopupCreditUnavailable = true // Кредит пока недоступен 50 ₼
        return // MIN Amount 50 man
      }

      if (
        notAvailableFirstCredit ||
        (this.customerStore.isLoggedIn &&
          (this.marketStore.cart?.credit_allowed === 'NOT_AVAILABLE' ||
            this.marketStore.cart?.credit_allowed ===
              'NOT_AVAILABLE_WITH_ITEMS'))
      ) {
        await this.goToCart()
        this.uiStore.buyOnCreditPopupWarning = true
        return
      }
      if (
        (this.marketStore.cart?.credit_allowed === 'AVAILABLE' ||
          this.marketStore.cart?.credit_allowed ===
            'NOT_AVAILABLE_BY_AMOUNT') &&
        !notAvailableFirstCredit &&
        confirm
      ) {
        if (this.leastOneMoreAvailable) {
          this.uiStore.isOpenAvailablePopup = true
        } else if (this.leastOneCheckNeedProgress) {
          await this.goToCart()
          this.uiStore.isCheckoutWarningPopup = {
            visible: true,
            buttonFunc: this.goToCreditCheckoutFunc,
          }
          return
        } else if (this.everethingNotAvailable) {
          await this.goToCart()
          this.uiStore.isBasketNotAvailablePopup = true
          return
        } else {
          await this.goToCreditCheckoutFunc()
          return
        }
      } else {
        await this.goToCart()
        this.uiStore.buyOnCreditPopupWarning = true
        return
      }
      await this.goToCart()
      this.uiStore.BuyOnCreditPopupWarning = true
    },
    async goToCreditCheckoutFunc() {
      /* eslint-disable */
      const EventDataLayer = useEventDataLayer()
      const cart_id = this.$auth.$storage.getLocalStorage('cart_id')
      if (
        this.marketStore.cart?.credit_allowed !== 'AVAILABLE' &&
        this.marketStore.cart?.total_amount < 50 &&
        this.checkFirstCredit
      ) {
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({
            event: 'credit_scr_min_sum_false',
          })
        }
        this.uiStore.showWarnPopupCreditUnavailable = true
        return
      }

      EventDataLayer.SET_BEGIN_CHECKOUT_SOURSE(this.source)

      if (this.customerStore.isLoggedIn) {
        const checkoutStoreV3 = useCheckoutStoreV3()
        const checkoutStore = useCheckoutStore()
        const response = await checkoutStoreV3.getLoanPackagesCart()
        if (response === 'error') {
          await this.goToCart()
          checkoutStore.warning_modal_loan_application_created = true
          return
        }
        this.$router.push(
          this.localePath({
            name: 'checkout',
            query: {
              mode: 'loan',
            },
          }),
        )
        return
      }

      if (this.$auth.loggedIn) {
        await this.$router.push(
          this.localePath({
            name: 'checkout',
            query: {
              mode: 'loan',
            },
          }),
        )
      } else if (cart_id) {
        await this.$router.push(
          this.localePath({
            name: 'checkout-authentication',
            query: {
              mode: 'loan',
            },
          }),
        )
      }
    },
    async goToCart() {
      await this.$router.push({
        path: this.localePath('cart'),
        query: this.$route.query,
      })
    },

    goToHasError() {
      this.isOpenAvailablePopup = false
      const firstErrorQty = document.querySelector('.ProductQuantity.has-error')
      if (firstErrorQty)
        scrollTo(
          document.querySelector('.Layout'),
          firstErrorQty.offsetTop + 150,
          250,
        )
    },
  },
}
