
import { useUiStore } from '@/stores/ui'
export default {
  name: 'CheckoutAvailablePopup',
  setup() {
    const uiStore = useUiStore()
    return { uiStore }
  },
  methods: {
    closeModal() {
      this.uiStore.isOpenAvailablePopup = false
      this.$emit('close-modal')
    },
  },
}
