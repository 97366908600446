import { useCheckoutStoreV3 } from '@/stores/checkout-v3'
import { usePromoCodeStore } from '@/stores/promocode'
import { useCheckoutCredit } from '@/stores/checkout_credit'

export default {
  SET_DATA_LAYER_SOURCE_SEARCH(type) {
    this.data_layer_source = type
  },
  SET_BEGIN_CHECKOUT_METHODS(method) {
    this.begin_checkout_method = method
  },
  SET_BEGIN_CHECKOUT_SOURSE(source) {
    this.begin_checkout_source = source
  },
  SET_DATA_CLICK_SUGGESTS(type) {
    this.data_layer_click_suggests = type
  },
  async sendEventBirId(options) {
    // TODO only production
    if (
      process.env.NODE_ENV === 'production' &&
      this.$nuxt.$config.SINGLEID_GA_MEASUREMENT_ID &&
      this.$nuxt.$config.SINGLEID_GA_API_KEY
    ) {
      try {
        const resp = await fetch(
          `https://www.google-analytics.com/mp/collect?measurement_id=${this.$nuxt.$config.SINGLEID_GA_MEASUREMENT_ID}&api_secret=${this.$nuxt.$config.SINGLEID_GA_API_KEY}`,
          {
            method: 'POST',
            body: JSON.stringify(options),
            mode: 'no-cors', // Отключение CORS
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
      } catch (e) {
        console.log('sendEventBirId', e)
      }
    }
  },
  dataLayerAddShippingInfo() {
    const checkoutStoreV3 = useCheckoutStoreV3()
    const { orderPackage } = checkoutStoreV3
    let same_delivery_method = 'one'
    if (typeof dataLayer !== 'undefined') {
      if (checkoutStoreV3.orderPackage?.orders?.length > 1) {
        const firstDeliveryOrderId =
          checkoutStoreV3.orderPackage?.orders?.[0]?.delivery?.address?.id
        const hasOrderAddress = checkoutStoreV3.orderPackage?.orders?.find(
          order => order?.delivery?.address?.id !== firstDeliveryOrderId,
        )
        same_delivery_method = hasOrderAddress ? 'no' : 'yes'
      }
      orderPackage?.orders?.forEach(order => {
        const is_addres = checkoutStoreV3.applyAddressAllOrders
        const promocode = order?.promocode_discount ? order?.promocode : false
        const items = []
        order.line_items.map(item => {
          const fixedPrice = Math.round(item?.price * 100) / 100
          const set_item = {
            item_name: item?.name,
            item_id: item?.product_id,
            item_list_name: item?.src_item_list_name,
            price: fixedPrice,
            item_category: `${item?.category?.name}_${item?.category?.id}`,
            quantity: item.quantity,
            item_brand: item.brand,
          }
          if (promocode) set_item.coupon = promocode
          items.push(set_item)
        })
        const add_shipping_info = {
          event: 'add_shipping_info',
          shipping_tier: this.setDeliveryMethod(order.delivery.method.code),
          delivery_method: this.setDeliveryMethod(order.delivery.method.code),
          currency: 'azn',
          value: order?.total_amount,
          items,
          order_id: order.id,
          same_delivery_method,
          method: this.paymentMethodForCartPage(this.$nuxt.route),
        }
        add_shipping_info.delivery_address = is_addres ? 'yes' : 'no'
        if (promocode) add_shipping_info.coupon = promocode
        dataLayer.push(add_shipping_info)
      })
    }
  },

  setBeginCheckoutDataLayer() {
    const checkoutStoreV3 = useCheckoutStoreV3()
    const orderPackage = checkoutStoreV3.orderPackage
    const is_addres =
      checkoutStoreV3.orderPackage?.orders?.[0]?.delivery?.address?.id
    const items = []
    let orders_quantity = 0
    orderPackage?.orders.map(order => {
      orders_quantity += 1
      order?.line_items.map(item => {
        items.push({
          item_name: item?.name, // Name or ID is required.
          item_id: item?.product_id,
          price: item?.price,
          item_category: `${item?.category?.name}_${item?.category?.id}`,
          item_list_name: item?.src_item_list_name,
          item_list_id: item?.item_add_src,
          index:
            item?.src_item_list_index !== null
              ? item?.src_item_list_index + 1
              : null,
          item_brand: item?.brand,
          quantity: item?.quantity,
        })
      })
    })
    const begin_checkout = {
      event: 'begin_checkout',
      orders_quantity,
      delivery_address: is_addres ? 'yes' : 'no',
      items,
      value: orderPackage.total_amount,
      currency: 'azn',
      method: this.paymentMethodForCartPage(this.$nuxt.route),
    }
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push(begin_checkout)
    }
  },
  dataLayerPaymentInfo(val) {
    try {
      const checkoutStoreV3 = useCheckoutStoreV3()
      const orderPackage = checkoutStoreV3.orderPackage
      if (typeof dataLayer !== 'undefined') {
        const items = []
        orderPackage.orders.map(order => {
          order.line_items.map(item => {
            const fixedPrice = Math.round(item?.price * 100) / 100
            const set_item = {
              item_name: item?.product_name || item?.name,
              item_id: item?.product_id,
              item_list_id: item?.item_add_src,
              price: fixedPrice,
              item_category: `${item?.category?.name}_${item?.category?.id}`,
              quantity: item.quantity,
              item_brand: item.brand,
            }
            items.push(set_item)
          })
        })
        const add_payment_info = {
          event: 'add_payment_info',
          payment_type: this.setPaymentMethod(val),
          currency: 'azn',
          value: orderPackage.total_amount, // planet base payment amount
          items,
        }
        dataLayer.push(add_payment_info)
        this.oneTime = false
      }
    } catch (e) {
      console.log('dataLayerPaymentInfo error', e)
    }
  },
  dataLayerBtAddShippingInfo(errorResponse = '') {
    const checkoutStoreV3 = useCheckoutStoreV3()
    let same_delivery_method = 'one'
    if (checkoutStoreV3.orderPackage?.orders?.length > 1) {
      const firstDeliveryOrderId =
        checkoutStoreV3.orderPackage?.orders?.[0]?.delivery?.address?.id
      const hasOrderAddress = checkoutStoreV3.orderPackage?.orders?.find(
        order => order?.delivery?.address?.id !== firstDeliveryOrderId,
      )
      same_delivery_method = hasOrderAddress ? 'no' : 'yes'
    }
    let orderError = ''
    if (checkoutStoreV3.deliveryOrderErrors.length) {
      orderError = checkoutStoreV3.deliveryOrderErrors?.find(
        order => order.addressSelected,
      )
        ? 'delivery_address'
        : ''
      orderError = checkoutStoreV3.deliveryOrderErrors?.find(order => {
        return order.timeFrameSelected === false
      })
        ? 'delivery_time'
        : ''
    }
    const dataBtAdd = {
      event: 'bt_add_shipping_info',
      orders_quantity: checkoutStoreV3.orderPackage?.orders?.length,
      same_delivery_method,
    }
    if (orderError) dataBtAdd.field_invalid = orderError
    if (errorResponse) dataBtAdd.backend_error = errorResponse
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push(dataBtAdd)
    }
  },

  dataLayerClickPurchaseButton(error = {}) {
    let field_invalid = []
    const checkoutStoreV3 = useCheckoutStoreV3()
    const { orderPackage } = checkoutStoreV3
    const checkoutCredit = useCheckoutCredit()
    const isCreditCheckout = orderPackage.payment_method === 'LOAN_ON_DELIVERY'
    let method = 'regular'
    if (isCreditCheckout) {
      method = checkoutCredit.checkFirstCredit ? 'credit_new' : 'credit_old'
    }
    const { name, surname, phone_number, note } =
      orderPackage?.shipping?.recipient

    let contacts_name = name
    let contacts_surname = surname
    let contacts_phone = phone_number

    contacts_name = orderPackage?.shipping?.recipient?.name
    contacts_surname = orderPackage?.shipping?.recipient?.surname
    contacts_phone = orderPackage?.shipping?.recipient?.phone_number.replace(
      '+994',
      '',
    )

    const click_purchase_button = {
      event: 'click_purchase_button',
      method,
      payment_type: this.setPaymentMethod(orderPackage?.payment_method),
      delivery_method: this.setDeliveryMethod(
        orderPackage.shipping.method.code,
      ),

      contacts_name: !!contacts_name,
      contacts_surname: !!contacts_surname,
      contacts_phone: !!contacts_phone,
    }

    if (orderPackage.payment_method === 'CARD_ECOM_KB') {
      click_purchase_button.saved_card = orderPackage.token_id
        ? 'true'
        : 'false'
    }
    if (
      error?.phone_number?.$error ||
      error?.name?.$error ||
      error?.surname?.$error ||
      error?.additional_phone_number1?.$error ||
      error?.additional_phone_number2?.$error ||
      error?.fin_code?.$error ||
      error?.last_name?.$error ||
      error?.first_name?.$error
    ) {
      const list_error = []
      if (error?.name?.$error) list_error.push('name')
      if (error?.phone_number?.$error) list_error.push('phone_number')
      if (error?.additional_phone_number1?.$error)
        list_error.push('trusted_number_1')
      if (error?.additional_phone_number2?.$error)
        list_error.push('trusted_number_2')
      if (error?.fin_code?.$error) list_error.push('fin_code')
      if (error?.last_name?.$error) list_error.push('surname')
      if (error?.first_name?.$error) list_error.push('name')

      field_invalid = list_error.join(' | ')
    }
    if (field_invalid?.length) {
      click_purchase_button.field_invalid = field_invalid
    }

    if (this.errorEventPurchaseButton) {
      click_purchase_button.backend_error = this.errorEventPurchaseButton
    }
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push(click_purchase_button)
    }
  },

  setDataLayerPurchase() {
    const checkoutStoreV3 = useCheckoutStoreV3()
    const checkoutCredit = useCheckoutCredit()
    const { orderPackage } = checkoutStoreV3
    const isCreditCheckout = orderPackage.payment_method === 'LOAN_ON_DELIVERY'
    let method = 'regular'
    if (isCreditCheckout) {
      method = checkoutCredit.checkFirstCredit ? 'credit_new' : 'credit_old'
    }
    if (orderPackage.orders?.length) {
      orderPackage.orders.map(order => {
        const items = []
        const promocode = order?.promocode_discount ? order?.promocode : false

        order.line_items.map((item, index) => {
          const fixedPrice = Math.round(item?.price * 100) / 100
          const set_item = {
            item_id: item?.product_id,
            item_name: item?.name,
            item_category: `${item?.category?.name}_${item?.category?.id}`,
            item_list_name: item?.src_item_list_name,
            item_list_id: item?.item_add_src,
            item_brand: item.brand,
            price: fixedPrice,
            quantity: item.quantity,
            index:
              item?.src_item_list_index !== null
                ? item?.src_item_list_index
                : null,
          }
          if (promocode) set_item.coupon = promocode
          items.push(set_item)
        })

        const totalAmountAzn = Math.round(order.total_amount * 100) / 100
        this.sendDataLayerPurchase(order, totalAmountAzn, items, method)
      })
    }
  },
  sendDataLayerPurchase(order, totalAmountAzn, items, method) {
    const checkoutStoreV3 = useCheckoutStoreV3()
    const { orderPackage } = checkoutStoreV3
    const promoCodeStore = usePromoCodeStore()
    const purchase = {
      event: 'purchase',
      method,
      extra_cashback: orderPackage.premium_promocode ? 'active' : 'inactive',
      ecommerce: {
        purchase: {
          affiliation: order.seller?.id,
          transaction_id: order.number,
          value: totalAmountAzn,
          shipping: order.shipping_cost,
          currency: 'AZN',
          tax: 0,
          items,
          payment_type: this.setPaymentMethod(order?.payment_method?.code),
          shipping_tier: order?.shipping?.method?.code,
        },
      },
      payment_method: this.setPaymentMethod(order.payment_method.code),
      delivery_method: this.setDeliveryMethod(
        orderPackage.shipping.method.code,
      ),
    }

    if (orderPackage.promocode) purchase.coupon = orderPackage.promocode
    if (orderPackage.payment_method === 'CARD_ECOM_KB') {
      purchase.saved_card = Boolean(orderPackage.token_id)
      purchase.ecommerce.purchase.saved_card = Boolean(orderPackage.token_id)
    }
    if (promoCodeStore.has_subscription_promo_code) {
      purchase.switch_extra_cashback =
        promoCodeStore.has_subscription_promo_code ? 'on' : 'off'
    }
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push(purchase)
      dataLayer.push(function () {
        this.reset()
      })
    }
    promoCodeStore.SET_DATA_LAYER_HAS_SUBSCRIPTIOON_PROMO_CODE(false)
    promoCodeStore.SET_DATA_LAYER_HAS_PERSONAL_PROMO_CODE_SUBSCRIPTION({})
    promoCodeStore.SET_DATA_LAYER_PERSONAL_PROMO_CODE(false)
  },
  statusEcomPaymentKbDataLayer(
    orderPackage,
    status,
    { package_id, order_id, next_order_id },
  ) {
    const currentOrder = orderPackage.orders.find(order => {
      return order.id === order_id
    })
    const screen_view = {
      event: 'screen_view',
      space: 'market_fintech',
      subject: 'user',
      screen_type: 'typ',
      screen_name: 'status_ecom_payment_kb',
      package_id,
      status,
      order_id,
      transaction_id: currentOrder?.number,
    }
    if (next_order_id) {
      screen_view.next_order_id = next_order_id
    }
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push(screen_view)
    }
  },
}
