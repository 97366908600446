
import { useUiStore } from '@/stores/ui'

export default {
  name: 'CreditLineNotAvailableModal',
  setup() {
    const uiStore = useUiStore()
    return { uiStore }
  },
  data() {
    return {
      width: 560,
    }
  },
  computed: {
    showModal: {
      get() {
        return this.uiStore.creditLineNotAvailableModal
      },
      set(val) {
        this.uiStore.creditLineNotAvailableModal = val
      },
    },
  },
  methods: {
    closeModal() {
      this.uiStore.creditLineNotAvailableModal = false
      if (this.$route.name.includes('checkout-authentication')) {
        try {
          this.$router.push({
            path: this.localePath('cart'),
          })
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') throw error
        }
      }
    },
  },
}
